<template>
    <div id="ChartAccountValue">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                :editOnly="true"
                :serverSidePagination="true"
                :serverSideProps="serverSideProps"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"

                :tab="tab"
                :tabItems="tabItems"
                :setPopulateNotRequiredOptions="setPopulateNotRequiredOptions"
                @setBackPopulateNotRequiredOptions="setPopulateNotRequiredOptions = false"
            >
                <template v-slot:filterTabs>
                    <ZnapFilterTabHeader
                        :tab="tab"
                        :tabItems="tabItems"
                        @setFilterTab="setFilterTab"
                    >
                    </ZnapFilterTabHeader>
                </template>
                
                <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        @setClearFilters="setClearFilters"
                        @setDatepickerValue="setDatepickerValue"
                    >
                    </ZnapFilters>
                </template>
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapFilters from '@/components/znap/Filters.vue'
import ZnapFilterTabHeader from '@/components/znap/FilterTabs/Header.vue'

export default {
    name:'ChartAccountValue',

    components: { ZnapTable, ZnapFilters, ZnapFilterTabHeader },

    computed: {
        endpoint() {
            return [
                this.$ipOrganization,
                'chart-account-value',
                {
                    conditions: []
                }
            ]
        },
    },

    data: () => ({
        tab: 0,
        tabItems: ['Filtros', 'Filtros personalizados', 'Exibir 1', 'Exibir 2', 'Exibir 3', 'Exibir 4', 'Exibir 5', 'Opções'],

        tableRows: 500000,

        options: [
            {
                column: 'posting_date',
                is: 'Datepicker',
                items: [],
                multiple: false,
                label: 'De/ Até',
                required: true,
                filterable: false,
                endpoint: [],
                value: null,
            },
            {
                column: 'id_account_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'SEPIN',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipAccount, 'account-group' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_account_group_version',
                        operator: '=',
                        value: 1
                    }
                ]
            },
            {
                column: 'transaction_number',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Número da transação',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'chart-account-value', '/list-options-personalized' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: 'posting_date',
                        operator: "BETWEEN",
                        value: null,
                        value2: null,
                    }
                ]
            },
            {
                column: 'id_chart_account',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta contábil',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'chart-account' ]
            },
            {
                column: 'id_employee',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Funcionário',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'employee' ]
            },
            {
                column: 'id_chart_account_value_type',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tipo de lançamento contábil',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'chart-account-value-type' ]
            },
            {
                column: 'id_customer_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta (Cliente)',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'customer-group' ]
            },
            {
                column: 'id_cost_center',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Centro de custo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ]
            },
            {
                column: 'id_consolidation_key',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Chave de consolidação',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipPlPlanning, 'consolidation-key' ],
                conditions: [
                    {
                        AndOr: "AND",
                        column: 'posting_date',
                        operator: "BETWEEN",
                        value: null,
                        value2: null,
                    }
                ]
            },
            {
                column: 'id_currency',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Moeda',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUnit, 'currency' ]
            },
            {
                column: 'id_company',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Empresa',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'company' ]
            },
            {
                column: 'id_status',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Status',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUnit, 'status' ]
            },
            {
                column: 'id_sales_channel',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Canal de vendas',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSales, 'sales-channel' ]
            },
            {
                column: 'id_vendor',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Fornecedor',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipVendor, 'vendor' ]
            },
            {
                column: 'id_customer',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Cliente',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'customer' ]
            },
        ],

        noDataMessage: '',
        noData: true,

        serverSideProps: {
            label: 'Pesquisar na página atual',
            columns: [
                {
                    AndOr: 'OR',
                    column: 'id_account_group',
                    operator: 'CONTAINS'
                },
                {
                    AndOr: 'OR',
                    column: 'cd_account_group',
                    operator: 'CONTAINS'
                },
                {
                    AndOr: 'OR',
                    column: 'account_group_description',
                    operator: 'CONTAINS'
                },
            ]
        },

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
        setPopulateNotRequiredOptions: false,
    }),

    methods: {
        setFilterTab(e) {
            this.tab = e
        },

        setDatepickerValue(e) {
            let option = this.options.find(o => o.column === e.column)
            option.value = e.value

            if (e.value) this.setPopulateNotRequiredOptions = true
        },
        
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (typeof selection.id !== 'string' && selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            let datepickerConditions = this.options.filter(o => o.is === 'Datepicker')
            if (datepickerConditions.length) {
                datepickerConditions.forEach(d => {
                    d.value = null
                })
            }

            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>